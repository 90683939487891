export default function InvestProfit({ name, value, icon }) {
  return (
    <div className="flex items-center gap-3 border border-[#046ce6] p-3 rounded-lg">
      <div className="p-3 rounded-full bg-[#046ce6]">
        <img src={icon} alt="icon" className="w-[40px] h-[40px]" />
      </div>
      <div className="dark:text-white">
        <p>{name}</p>
        <div
          className="flex justify-center items-center file:font-bold py-1 px-3 rounded-lg"
          style={{
            background:
              "linear-gradient(266.52deg, #ecc101 6.69%, #0015b5 92.08%)",
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
}
