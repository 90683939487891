/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TransactionService from "services/transaction";
import { STYLE_STATUS, TRANSACTION_TYPE } from "utils/constant";
import {
  formatDate,
  formatNumber,
  getAddress,
} from "utils/common";

function RecentTransactionWidget(props, ref) {
  useImperativeHandle(ref, () => ({
    reload: () => {
      getTransactions(filterActive);
    },
  }));

  const transationFilterData = [
    {
      id: 1,
      name: "all",
      uniqueId: Math.random(),
    },
    {
      id: 2,
      name: "deposit",
      uniqueId: Math.random(),
    },
    {
      id: 3,
      name: "withdraw",
      uniqueId: Math.random(),
    },
  ];
  const [filterActive, setFilterActive] = useState(transationFilterData[0]);
  const [transaction, setTransaction] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [lazyParams, setLazyParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    getTransactions(filterActive);
  }, [lazyParams]);

  const filterHander = (value) => {
    setFilterActive(value);
    getTransactions(value);
  };

  const getTransactions = async (filterActive) => {
    try {
      const res = await TransactionService.getTransactions({
        query: {
          ...lazyParams,
          type:
            filterActive.id === 1 ? ["withdraw", "deposit"] : filterActive.name,
        },
      });
      setTransaction(res);
    } catch (error) {}
  };

  const nextPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page + 1,
    }));
  };

  const prevPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page - 1,
    }));
  };

  return (
    <div className="recent-transaction-widget w-full h-full p-7 rounded-2xl bg-white dark:bg-dark-white  ">
      {/* heading */}
      <div className="heading sm:flex justify-between items-center">
        <div>
          <h1 className="text-xl font-bold tracking-wide   text-dark-gray dark:text-white">
            Recent Transaction
          </h1>
        </div>
        <div>
          <ul className="flex space-x-5 items-center">
            {transationFilterData.map((value) => (
              <li
                onClick={() => filterHander(value)}
                key={value.uniqueId}
                className={`text-base text-thin-light-gray hover:text-purple border-b dark:border-[#5356fb29]  border-transparent hover:border-purple uppercase ${
                  filterActive.id === value.id
                    ? "border-purple text-purple"
                    : ""
                }`}
              >
                {value.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* content */}

      <div className="content overflow-auto h-full">
        <ul>
          {transaction.docs.map((tran) => (
            <li
              key={tran._id}
              className="content-item py-3 border-b dark:border-[#5356fb29]  border-light-purple dark:border-[#5356fb29]  hover:border-purple"
            >
              <div className="flex justify-between items-center">
                <div className="account-name flex space-x-4 items-center">
                  <div className="icon w-14 h-14 flex justify-center items-center">
                    <img src={TRANSACTION_TYPE[tran.type].logo} alt=""  width={40}/>
                  </div>
                  <div>
                    <div className="name">
                      <p className="text-base text-dark-gray dark:text-white font-medium mb-1">
                        {TRANSACTION_TYPE[tran.type].name}
                      </p>
                    </div>
                    <div className="time">
                      <a
                        target="_blank"
                        href={`https://bscscan.com/tx/${tran.hash}`}
                        className="text-[#ff9800] text-sm sfont-medium"
                        rel="noreferrer"
                      >
                        {getAddress(tran.hash)}
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  {tran.amount > 0 ? (
                    <p className="eth text-xl font-bold text-right text-light-green">
                      {formatNumber(tran.amount)}
                    </p>
                  ) : (
                    <p className="eth text-xl font-bold text-right text-light-red">
                      {formatNumber(tran.amount)}
                    </p>
                  )}

                  <div className="time text-right">
                    <button type="button" className={STYLE_STATUS[tran.status]}>
                      {tran.status}
                    </button>
                    <p className="text-sm text-thin-light-gray font-medium">
                      {formatDate(tran.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-end pb-4 mt-2">
        <button
          disabled={!transaction.hasPrevPage}
          onClick={prevPage}
          className="inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled={!transaction.hasNextPage}
          onClick={nextPage}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          Next
          <svg
            aria-hidden="true"
            className="w-5 h-5 ml-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
export default forwardRef(RecentTransactionWidget);
