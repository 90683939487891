/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { GlobalContext } from "contexts/GlobalContext";
import { useCallback, useContext, useEffect, useState } from "react";
import TransactionService from "services/transaction";
import { formatDate, getTextByNumber } from "utils/common";
import { STYLE_STATUS, TRANSACTION_TYPE } from "utils/constant";
import SelectBox from "../Helpers/SelectBox";

export default function HistoryTable({ className }) {
  const filterCategories = [
    "All",
    "Deposit",
    "Withdraw",
    "Commission",
    "Profit",
    "Invest",
    "Payback",
    "Salary",
  ];

  const { system } = useContext(GlobalContext);
  const [selectedCategory, setSelectedCategory] = useState(filterCategories[0]);

  const [transaction, setTransaction] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [lazyParams, setLazyParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    getTransactions(selectedCategory);
  }, [lazyParams]);

  const filterHander = (value) => {
    setSelectedCategory(value);
    getTransactions(value);
  };

  const getTransactions = useCallback(async (filterActive) => {
    try {
      const res = await TransactionService.getTransactions({
        query: {
          ...lazyParams,
          type:
            filterActive === "All"
              ? [
                  "withdraw",
                  "deposit",
                  "profit",
                  "commission",
                  "invest",
                  "transfer",
                  "payback",
                  "salary",
                ]
              : String(filterActive).toLowerCase(),
        },
      });
      setTransaction(res);
    } catch (error) {}
  });

  const nextPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page + 1,
    }));
  };

  const prevPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page - 1,
    }));
  };
  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px] ${
        className || ""
      }`}
    >
      <div className="header w-full flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center">
          <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
            Transactions History
          </h1>
        </div>
        <SelectBox
          action={filterHander}
          datas={filterCategories}
          className="Update-table-dropdown"
          contentBodyClasses="w-auto min-w-max"
        />
      </div>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">
              <td className="py-4 text-center hidden md:block">#</td>
              <td className="py-4 ">Transaction</td>
              <td className="py-4">Amount</td>
              <td className="py-4 text-center">Time</td>
              <td className="py-4 text-center">Status</td>
            </tr>

            <>
              {transaction.docs.map((tran, key) => (
                <tr
                  key={key}
                  className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50"
                >
                  <td className="py-4 hidden md:block">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={TRANSACTION_TYPE[tran.type].logo}
                          alt="data"
                          className="w-full h-[50px] "
                        />
                      </div>
                      <p className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                        #{getTextByNumber(tran._id, 10)}
                      </p>
                    </div>
                  </td>
                  <td className="py-4 px-2">
                    <div className="">
                      <p className="text-[#ba07c5]">
                        {TRANSACTION_TYPE[tran.type].name}
                      </p>
                      <p className="text-[#4caf50]">{tran.description}</p>
                    </div>
                  </td>
                  <td className="py-4 px-2">
                    <div className="flex space-x-1 ">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        {tran.amount > 0 ? (
                          <span className="text-[#4caf50]">+{tran.amount}</span>
                        ) : (
                          <span className="text-light-red"> {tran.amount}</span>
                        )}
                        <span className="ml-1 text-[#ff9800]">
                          {system.token}
                        </span>
                      </span>
                    </div>
                  </td>

                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                      {formatDate(tran.createdAt)}
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button type="button" className={STYLE_STATUS[tran.status]}>
                      {tran.status}
                    </button>
                  </td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
        <div className="flex justify-end pb-4 mt-2">
          <button
            disabled={!transaction.hasPrevPage}
            onClick={prevPage}
            className="inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          <button
            disabled={!transaction.hasNextPage}
            onClick={nextPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
            <svg
              aria-hidden="true"
              className="w-5 h-5 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
