import { IconLevel } from "components/Helpers/Icons";

export const TRANSACTION_TYPE = {
  withdraw: {
    name: "Withdraw",
    logo: "assets/images/withdraw.webp",
  },
  transfer: {
    name: "Transfer",
    logo: "assets/images/transfer.png",
  },
  deposit: {
    name: "Deposit",
    logo: "assets/images/deposit.svg",
  },
  commission: {
    name: "Commission",
    logo: "assets/images/direct_commission.svg",
  },
  profit: {
    name: "Profit",
    logo: "assets/images/profit_day.svg",
  },
  invest: {
    name: "Payback",
    logo: "assets/images/invest1.svg",
  },
  payback: {
    name: "Payback",
    logo: "assets/images/invest1.svg",
  },
  salary: {
    name: "Salary",
    logo: "assets/images/salary.png",
  },
};

export const INVEST_PERIOD = [
  {
    id: "1",
    name: "6 months",
    percent: 12,
    time: 6,
    thumbnail: "assets/images/period_1.png",
    profit_daily: 2 / 3000,
  },
  {
    id: "2",
    name: "12 months",
    percent: 36,
    time: 12,
    thumbnail: "assets/images/period_2.png",
    profit_daily: 3 / 3000,
  },
  {
    id: "3",
    name: "18 months",
    percent: 90,
    time: 18,
    thumbnail: "assets/images/period_3.png",
    profit_daily: 5 / 3000,
  },
];

export const INVEST_PACKAGE = [
  {
    id: "1",
    name: "BRONZE",
    percent: 3,
    icon: "assets/images/bronze.png",
    value: 500,
  },
  {
    id: "2",
    name: "SILVER",
    percent: 5,
    icon: "assets/images/silver.png",
    value: 1000,
  },
  {
    id: "3",
    name: "GOLD",
    percent: 6,
    icon: "assets/images/gold.png",
    value: 5000,
  },
  {
    id: "4",
    name: "PLATINUM",
    percent: 8,
    icon: "assets/images/platinum.png",
    value: 10000,
  },
  {
    id: "5",
    name: "DIAMOND",
    percent: 9,
    icon: "assets/images/diamond.png",
    value: 30000,
  },
  {
    id: "6",
    name: "INVESTOR",
    percent: 10,
    icon: "assets/images/investor.png",
    value: 50000,
  },
];

export const STATUS_CLASS = {
  success: "text-sm text-white bg-light-green px-2.5 py-1.5 rounded-full",
  error: "text-sm text-white bg-light-red px-2.5 py-1.5 rounded-full",
};

export const LEVEL_INT = {
  MEMBER: 0,
  BEGINNER: 1,
  SILVER: 2,
  GOLD: 3,
  RUBY: 4,
  DINAMOND: 5,
  CROWN: 6,
  FOUNDER_RUBY: 7,
  FOUNDER_DIAMOND: 8,
  FOUNDER_CROWN: 9,
};

export const LEVELS = [
  {
    id: "1",
    name: "BEGINNER",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="beginner" />
        <p>Beginner</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>+5,000/Branch</p>
      </>
    ),
    salary: (
      <>
        <p>+200$</p>
      </>
    ),
    bonus: "+500$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Beginner/Branch</p>
        <span className="text-[#4caf50]">+500$</span>
      </>
    ),
  },

  {
    id: 2,
    name: "SILVER",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="silver" />
        <p>Silver</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>Beginner/Branch</p>
      </>
    ),
    salary: (
      <>
        <p>+500$</p>
      </>
    ),
    bonus: "+2,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Silver/Branch</p>
        <span className="text-[#4caf50]">+2,000$</span>
      </>
    ),
  },

  {
    id: 3,
    name: "GOLD",
    level: (
      <div className="flex items-center flex-col md:w-[100%] w-[85px]">
        <IconLevel name="gold" />
        <p>Gold</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>Silver/Branch</p>
      </>
    ),
    salary: (
      <>
        <p>+1,000$</p>
      </>
    ),
    bonus: "+5,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Gold/Branch</p>
        <span className="text-[#4caf50]">+5,000$</span>
      </>
    ),
  },

  {
    id: 4,
    name: "RUBY",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="ruby" />
        <p>Ruby</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>Gold/Branch</p>
      </>
    ),
    salary: (
      <>
        <p>+2,000$</p>
      </>
    ),
    bonus: "+15,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Ruby/Branch</p>
        <span className="text-[#4caf50]">+15,000$</span>
      </>
    ),
  },

  {
    id: 5,

    name: "DIAMOND",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="diamond" />
        <p>Diamond</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>Ruby/Branch</p>
      </>
    ),

    salary: (
      <>
        <p>+5,000$ </p>
      </>
    ),
    bonus: "+30,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Diamond/Branch</p>
        <span className="text-[#4caf50]">+30,000$</span>
      </>
    ),
  },

  {
    id: 6,
    name: "CROWN",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="crown" />
        <p>Crown</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Branch</p>
        <p>Diamond/Branch</p>
      </>
    ),
    salary: (
      <>
        <p>+10,000$</p>
      </>
    ),
    bonus: "+60,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+1 Crown/Branch</p>
        <span className="text-[#4caf50]">+60,000$</span>
      </>
    ),
  },

  {
    id: 7,
    name: "FOUNDER_RUBY",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="founder_ruby" />
        <p>Founders Ruby</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Bonus every period</p>
      </>
    ),
    salary: (
      <>
        <p>+20,000$</p>
      </>
    ),
    bonus: "+100,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+3,000,000$</p>
        <p className="text-[#ff9800]">
          Request each branch{" "}
          <span>
            <br /> &gt; 100,000 (3 Branch)
          </span>
        </p>
        <span className="text-[#4caf50]">+100,000</span>
      </>
    ),
  },

  {
    id: 8,
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="founder_diamond" />
        <p>Founders Diamond</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Bonus every period</p>
      </>
    ),
    name: "FOUNDER_DIAMOND",
    salary: (
      <>
        <p>+50,000$</p>
      </>
    ),
    bonus: "+200,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+5,000,000$</p>
        <p className="text-[#ff9800]">
          Request each branch{" "}
          <span>
            <br /> &gt; 150,000 (3 Branch)
          </span>
        </p>
        <span className="text-[#4caf50]">+200,000</span>
      </>
    ),
  },

  {
    id: 9,
    name: "FOUNDER_CROWN",
    level: (
      <div className="flex items-center flex-col">
        <IconLevel name="founder_crown" />
        <p>Founders Crown</p>
      </div>
    ),
    condition: (
      <>
        <p>+3 Bonus every period</p>
      </>
    ),
    salary: (
      <>
        <p>+100,000$</p>
      </>
    ),
    bonus: "+500,000$",
    month: 18,
    reward: (
      <>
        <p className="text-[#f44336]">+8,000,000$</p>
        <p className="text-[#ff9800]">
          Request each branch{" "}
          <span>
            <br /> &gt; 200,000 (3 Branch)
          </span>
        </p>
        <span className="text-[#4caf50]">+500,000</span>
      </>
    ),
  },
];

export const STYLE_STATUS = {
  success: "text-sm text-white bg-purple px-2.5 py-1.5 rounded-full",
  error: "text-sm text-white bg-light-red px-2.5 py-1.5 rounded-full",
  declined: "text-sm text-white bg-light-red px-2.5 py-1.5 rounded-full",
  pending: "text-sm text-white bg-[#ff9800]  px-2.5 py-1.5 rounded-full",
  completed: "text-sm text-white bg-light-green px-2.5 py-1.5 rounded-full",
  approved: "text-sm text-white bg-light-green px-2.5 py-1.5 rounded-full",
};

export const INVEST_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  WAITING_USER_CONFIRM: "waiting_user_confirm",
  WAITING_ADMIN_CONFIRM: "waiting_admin_confirm",
  WAITING_PAYBACK: "waiting_payback",
  ERROR: "error",
  COMPLETED: "completed",
  REINVESTED: "reinvested",
};

export const INVEST_STYLE_STATUS = {
  pending: [
    "text-sm text-white bg-[#ff9800] px-2.5 py-1.5 rounded-full",
    "Pending",
  ],
  success: [
    "text-sm text-white bg-light-green px-2.5 py-1.5 rounded-full",
    "Success",
  ],
  waiting_user_confirm: [
    "text-sm text-white bg-[#f35757] px-2.5 py-1.5 rounded-full",
    "Waiting user confirm",
  ],
  waiting_admin_confirm: [
    "text-sm text-white bg-[#ff9900] px-2.5 py-1.5 rounded-full",
    "Waiting admin confirm",
  ],
  waiting_payback: [
    "text-sm text-white bg-[#ff5800] px-2.5 py-1.5 rounded-full",
    "Waiting payback",
  ],
  error: [
    "text-sm text-white bg-light-red px-2.5 py-1.5 rounded-full",
    "Error",
  ],
  completed: [
    "text-sm text-white bg-[#969696] px-2.5 py-1.5 rounded-full",
    "Completed",
  ],
  reinvested: [
    "text-sm text-white bg-purple px-2.5 py-1.5 rounded-full",
    "Reinvested",
  ],
};
