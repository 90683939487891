import DataIteration from "../Helpers/DataIteration";
import SectionTitle from "../Helpers/SectionTitle";
import Package from "./Package";

export default function Packages({ data }) {
  return (
    <div className="col-span-7 md:col-span-4">
      <SectionTitle name="Investment packages" />
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
        <DataIteration datas={data} startLength={0} endLength={data?.length}>
          {({ datas }) => (
            <Package
              key={datas.id}
              name={datas.name}
              percent={datas.percent}
              icon={datas.icon}
              value={datas.value}
            />
          )}
        </DataIteration>
      </div>
    </div>
  );
}
