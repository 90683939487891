/* eslint-disable react-hooks/exhaustive-deps */
import DarkModeContext from "components/Contexts/DarkModeContext";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useMemo } from "react";
import ReactLoading from "react-loading";
import { LEVELS } from "utils/constant";
import SectionTitle from "../Helpers/SectionTitle";

export default function Leveling({members, levelProgress}) {
  const { parameter, usersGroup, auth } = useContext(GlobalContext);
  const { colors } = useContext(DarkModeContext);

  console.log("members: ", members)

  const amount = useMemo(() => {
    if (usersGroup) {
      return usersGroup.filter(
        (u) =>
          u.count.level === parameter.level && u._id !== parameter.object_id && u.referred_by === auth.refer_code
      ).length;
    }
    return 0;
  }, [parameter.level, usersGroup]);

  return (
    <div className="mb-5">
      <SectionTitle name="Leveling up conditions" className="mb-4" />
      <div
        className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px]`}
      >
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          <table className="w-full text-white overflow-hidden text-dark-gray dark:text-white">
            <thead className="rounded-md">
              <tr
                className={`text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29] default-border-b`}
              >
                <th className="p-3">Level</th>
                <th className="p-3">Leveling up conditions</th>
                <th className="p-3">Monthly salaries</th>
                <th className="p-3">Bonus when leveling up</th>
                <th className="p-3">Branch level up reward</th>
              </tr>
            </thead>
            <tbody>
              {LEVELS.map((level) => (
                <tr
                  className={`text-center border-b dark:border-[#5356fb29]`}
                  style={{
                    background:
                      parameter.level === level.name
                        ? `linear-gradient(266.52deg, ${colors.blue_600} 6.69%,  ${colors.yellow_600} 92.08%)`
                        : "",
                  }}
                  key={level.id}
                >
                  <td className={`p-3 text-center`}>{level.level}</td>
                  <td className={`p-3`}>
                    {level.condition}
                    {parameter.level === level.name && (
                      <div className="flex justify-center">
                        <ReactLoading
                          type={"spokes"}
                          color="#36d7b7"
                          width={20}
                          height={20}
                        />
                        <span className="ml-1 font-bold text-[#36d7b7]">
                          Progress {levelProgress}/3
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="p-3 text-[#4caf50]">{level.salary}</td>
                  <td className="p-3 text-[#4caf50]">{level.bonus}</td>
                  <td className="p-3 ">{level.reward}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
