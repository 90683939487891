import { twMerge } from "tailwind-merge";

export default function SectionTitle({ name, className }) {
  const classes = twMerge(`border-b ${className ?? ""}`);
  return (
    <div className={classes}>
      <p className="relative py-3 font-bold text-2xl text-[#046ce6] after:content-[' '] after:w-44 after:h-1 after:block after:absolute after:-bottom-[2.5px] after:bg-[#046ce6]">
        {name}
      </p>
    </div>
  );
}
