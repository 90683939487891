/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import back from "assets/images/kyc/back.png";
import front from "assets/images/kyc/front.png";
import selfie from "assets/images/kyc/selfie.jpg";
import loadingGif from "assets/images/loading.gif";
import Button from "components/Helpers/Button";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import KycService from "services/kyc";
import UploadService from "services/uploads";
import { formatDateTagP, formatInputDate, getAge } from "utils/common";
import { STYLE_STATUS } from "utils/constant";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs/InputCom";

export default function VerifyAccountTab() {
  const { auth, getUserAuth } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const selfieRef = useRef(null);
  const [state, setState] = useState({
    id: "",
    value_kyc: {},
    status: "",
    frontImage: front,
    backImage: back,
    selfieImage: selfie,
    birthday: "",
  });
  const [isMobile, setIsMobile] = useState(false);

  const [verifyPhoneNumberState, setVerifyPhoneNumberState] = useState({
    id: "",
    phone: "",
    countryCode: "81",
    confirmCode: "",
  });

  const [file, setFile] = useState({
    frontFile: null,
    backFile: null,
    selfieFile: null,
  });

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState({
    front: false,
    back: false,
    selfie: false,
  });

  const [isValidBirthday, setIsValidBirthday] = useState(true);

  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };

  useEffect(() => {
    getKyc();
  }, []);

  const getKyc = async () => {
    try {
      const res = await KycService.get();
      console.log({
        res,
      });
      if (res) {
        const birthday = formatInputDate(new Date(auth.birthday));
        setState((prev) => ({
          ...prev,
          ...res,
          birthday: birthday,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
      // value_kyc: {},
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (
        !state.value_kyc.front ||
        !state.value_kyc.back ||
        !state.value_kyc.selfie
      ) {
        setLoading(false);
        toast.error("Please upload file!");
        return;
      }
      // const formData1 = new FormData();
      // formData1.append("files", file.frontFile);
      // const _front = await UploadService.uploadFile({ body: formData1 });

      // const formData2 = new FormData();
      // formData2.append("files", file.backFile);
      // const _back = await UploadService.uploadFile({ body: formData2 });

      // const formData3 = new FormData();
      // formData3.append("files", file.selfieFile);
      // const _selfie = await UploadService.uploadFile({ body: formData3 });

      await KycService.verify({
        body: {
          id: state.id,
          birthday: state.birthday,
          value_kyc: {
            ...state.value_kyc,
          },
        },
      });
      setLoading(false);
      getUserAuth();
      getKyc();
      toast.success("Success");
    } catch (error) {
      console.log(error);
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setState(auth);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        500,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        200,
        200
      );
    });

  const imageChangehandler = async (e, type) => {
    if (e.target.value !== "") {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        [type]: true
      })
      // const imgReader = new FileReader();
      const file = e.target.files[0];

      // if (file.size > 1 * 1000 * 1024) {
      //   file = await resizeFile(file);
      // }

      // imgReader.onload = (event) => {
      //   onChange(stateName, event.target.result);
      // };
      // imgReader.readAsDataURL(file);

      // setFile((prev) => ({
      //   ...prev,
      //   [fileName]: file,
      // }))

      //
      const formData = new FormData();
      formData.append("files", file);
      const _img = await UploadService.uploadFile({ body: formData });
      setState({
        ...state,
        value_kyc: {
          ...state.value_kyc,
          [type]: _img.filePath,
        },
      });
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        [type]: false
      })
    }
  };

  // ======= Front Img
  const frontChangHandler = async (e) => {
    e.preventDefault();
    await imageChangehandler(e, "front");
  };

  // ======= Back img
  const backChangHandler = async (e) => {
    e.preventDefault();
    await imageChangehandler(e, "back");
  };

  // ===== Selffie
  const selfieChangHandler = async (e) => {
    e.preventDefault();
    await imageChangehandler(e, "selfie");
  };

  const statusColor = {
    pending: "#fef885",
    approved: "#22C55E",
  };

  const handleOnchangeBirthday = (e) => {
    console.log(typeof e.target.value);
    const age = getAge(e.target.value);
    if (age >= 18) {
      setIsValidBirthday(true);
      onChange("birthday", e.target.value);
    } else {
      setIsValidBirthday(false);
    }
  };

  const checkDisableButton = () => (
      !state.id ||
      !isValidBirthday ||
      !state.birthday ||
      // auth.phone !==`+${verifyPhoneNumberState.countryCode}${verifyPhoneNumberState.phone}` || 
      !state.value_kyc.front ||
      !state.value_kyc.back ||
      !state.value_kyc.selfie ||
      isLoadingUploadFile.front ||
      isLoadingUploadFile.back ||
      isLoadingUploadFile.selfie
    )

  return (
    <>
      {state && (
        <>
          <div className="personal-info-tab w-full flex flex-col justify-between">
            <div className="">
              <div className="sm:mr-10 text-dark-gray dark:text-white ">
                {state.status && state.status !== "declined" && (
                  <div className="fields w-full mb-3">
                    Status:{" "}
                    <button
                      type="button"
                      className={`${
                        STYLE_STATUS[state.status]
                      } kyc-status-button`}>
                      {state.status}
                    </button>
                    {/* <span className={`text-[${statusColor[state.status]}]`}>
                      {state.status}
                    </span> */}
                  </div>
                )}

                <div className="fields grid lg:grid-cols-2 gap-[20px]">
                  <div className="field ol-span-1 mb-6">
                    <InputCom
                      label="Identity Number"
                      type="text"
                      name="id"
                      placeholder="1427*********"
                      value={state.id}
                      disabled={state.status === "approved"}
                      inputHandler={(e) => onChange("id", e.target.value)}
                    />
                  </div>
                  <div className="field ol-span-1 mb-6">
                    {/* <InputCom
                      label="Birthday"
                      type="date"
                      name="birthday"
                      placeholder="Birthday"
                      value={state.birthday}
                      disabled={state.status === "approved"}
                      inputHandler={handleOnchangeBirthday}
                    /> */}
                    { state.status === "approved" 
                    ? <><label className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5">Birthday</label><p className="mb-2.5 px-6 rounded-[50px] dark:border-[#5356fb29] border h-[58px] dark dark:bg-[#11131F] flex items-center">{formatDateTagP(state.birthday)}</p></> 
                    : <InputCom
                      label="Birthday"
                      type="date"
                      name="birthday"
                      placeholder="Birthday"
                      value={state.birthday}
                      disabled={state.status === "approved"}
                      inputHandler={handleOnchangeBirthday}
                    />}
                    {!isValidBirthday && (
                      <p className="text-red-400">
                        You are not old enough to register!
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid lg:grid-cols-2">
                  <div className="col-span-1">
                    <div className="w-[340px] mb-10 m-auto">
                      <div className="update-profile w-full mb-9">
                        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white flex items-center mb-2">
                          Front ID
                          <span className="ml-1">
                            <Icons name="block-question" />
                          </span>
                        </h1>
                        <p className="text-base text-thin-light-gray mb-5">
                          Front of at least Size
                          <span className="ml-1   text-dark-gray dark:text-white">
                            340*200
                          </span>
                          . Gifs work too.
                          <span className="ml-1   text-dark-gray dark:text-white">
                            Max 5mb
                          </span>
                          .
                        </p>
                        <div className="flex justify-center">
                          <div className="w-full relative">
                            {isLoadingUploadFile.front ? (
                              <div className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px] flex justify-center place-items-center">
                                <img
                                  src={loadingGif}
                                  alt=""
                                  className="w-[50px] h-[50px]"
                                />
                              </div>
                            ) : (
                              <img
                                src={state.value_kyc?.front || state.frontImage}
                                alt=""
                                className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px]  overflow-hidden object-cover"
                              />
                            )}
                            <input
                              ref={frontRef}
                              onChange={(e) => frontChangHandler(e)}
                              type="file"
                              className="hidden"
                            />
                            {(!state.status ||
                              state.status === "pending" ||
                              state.status === "declined") && (
                              <div
                                onClick={() => frontRef.current.click()}
                                className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray  cursor-pointer">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="w-[340px] mb-10 m-auto">
                      <div className="update-profile w-full mb-9">
                        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white flex items-center mb-2">
                          Back ID
                          <span className="ml-1">
                            <Icons name="block-question" />
                          </span>
                        </h1>
                        <p className="text-base text-thin-light-gray mb-5">
                          Front of at least Size
                          <span className="ml-1   text-dark-gray dark:text-white">
                            340*200
                          </span>
                          . Gifs work too.
                          <span className="ml-1   text-dark-gray dark:text-white">
                            Max 5mb
                          </span>
                          .
                        </p>
                        <div className="flex justify-center">
                          <div className="w-full relative">
                            {isLoadingUploadFile.back ? (
                              <div className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px] flex justify-center place-items-center">
                                <img
                                  src={loadingGif}
                                  alt=""
                                  className="w-[50px] h-[50px]"
                                />
                              </div>
                            ) : (
                              <img
                                src={state.value_kyc?.back || state.backImage}
                                alt=""
                                className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px]  overflow-hidden object-cover"
                              />
                            )}
                            <input
                              ref={backRef}
                              onChange={(e) => backChangHandler(e)}
                              type="file"
                              className="hidden"
                            />
                            {(!state.status ||
                              state.status === "pending" ||
                              state.status === "declined") && (
                              <div
                                onClick={() => backRef.current.click()}
                                className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray  cursor-pointer">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid lg:grid-cols-2">
                  <div className="col-span-1">
                    <div className="w-[340px] mb-10 m-auto">
                      <div className="update-profile w-full mb-9">
                        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white flex items-center mb-2">
                          Selfie
                          <span className="ml-1">
                            <Icons name="block-question" />
                          </span>
                        </h1>
                        <p className="text-base text-thin-light-gray mb-5">
                          Selfie of at least Size
                          <span className="ml-1   text-dark-gray dark:text-white">
                            340*200
                          </span>
                          . Gifs work too.
                          <span className="ml-1   text-dark-gray dark:text-white">
                            Max 5mb
                          </span>
                          .
                        </p>
                        <div className="flex justify-center">
                          <div className="w-full relative">
                            {isLoadingUploadFile.selfie ? (
                              <div className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px] flex justify-center place-items-center">
                                <img
                                  src={loadingGif}
                                  alt=""
                                  className="w-[50px] h-[50px]"
                                />
                              </div>
                            ) : (
                              <img
                                src={state.value_kyc?.selfie || state.selfieImage}
                                alt=""
                                className="sm:w-[340px] sm:h-[200px] w-[300px] h-[170px]  overflow-hidden object-cover"
                              />
                            )}
                            <input
                              ref={selfieRef}
                              onChange={(e) => selfieChangHandler(e)}
                              type="file"
                              accept="image/*"
                              capture
                              className="hidden"
                            />
                            {(!state.status ||
                              state.status === "pending" ||
                              state.status === "declined") && (
                              <>
                                <div
                                  onClick={() => {
                                    selfieRef.current.click();
                                  }}
                                  className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray  cursor-pointer flex justify-center">
                                  <svg
                                    width="20"
                                    height="32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    version="1.1"
                                    id="Capa_1"
                                    viewBox="0 0 487 487">
                                    <g>
                                      <g>
                                        <path
                                          d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z     M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9    v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z     M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z     M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"
                                          fill="white"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <VerifyPhoneNumber
              kyc_status={state.status}
              verifyPhoneNumberState={verifyPhoneNumberState}
              setVerifyPhoneNumberState={setVerifyPhoneNumberState}
            /> */}

            {(!state.status ||
              state.status === "pending" ||
              state.status === "declined") && (
              <div className="content-footer w-full">
                <div className="w-full h-[120px] border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
                  <div className="flex space-x-4 mr-9">
                    <button
                      type="button"
                      className="text-18 text-light-red tracking-wide "
                      onClick={handleCancel}>
                      <span className="border-b dark:border-[#5356fb29]  border-light-red">
                        {" "}
                        Cancel
                      </span>
                    </button>
                    <Button
                      disabled={checkDisableButton()}
                      loading={loading}
                      onClick={(_) => handleSubmit()}
                      className="w-[152px] h-[46px] flex justify-center items-center btn-gradient text-base rounded-full text-white">
                      Verify
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* border line */}
          {/* <div className="w-full h-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mb-6"></div> */}
        </>
      )}
    </>
  );
}
