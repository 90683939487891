import DataIteration from "../Helpers/DataIteration";
import Category from "./Category";

export default function InvestCategory ({data}) {
  return <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    <DataIteration
      datas={data}
      startLength={0}
      endLength={data.length}
    >
      {({ datas }) => (
        <Category key={datas.id}  isShowLogo={datas.isShowLogo} name={datas.name} value={datas.value} icon={datas.icon}/>
      )}
    </DataIteration>
  </div>
}