/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from "react";
import Layout from "../Partials/Layout";
import Hero from "./Hero";
import TopSellerTopBuyerSliderSection from "./TopSellerTopBuyerSliderSection";
import MemberCategory from "components/Member/MemberCategory";
import { formatNumber } from "utils/common";
import InvestCategory from "components/Invest/InvestCategory";
import { GlobalContext } from "contexts/GlobalContext";

export default function Home() {
  const { parameter, system, levelInvest, auth, usersGroup } =
    useContext(GlobalContext);
  const { count } = parameter;
  const amountGroup = useMemo(() => {
    if (auth && usersGroup && usersGroup.length > 0) {
      return usersGroup.filter((u) => u.referred_by === auth.refer_code).length;
    }
    return 0;
  }, [auth, usersGroup]);

  const memberCategories = [
    {
      id: "1",
      name: "Your level",
      value: parameter.level || "MEMBER",
      icon: `assets/images/levels/${String(
        parameter.level || "member"
      ).toLowerCase()}.png`,
    },
    {
      id: "2",
      name: "Group",
      value: amountGroup,
      icon: "assets/images/member1.svg",
    },
    {
      id: "3",
      name: "Total sales",
      value: formatNumber(parameter?.count.revenue_group || 0),
      icon: "assets/images/total_sales.svg",
      unit: system.token,
    },
  ];
  const investCategories = [
    {
      id: "1",
      name: "Total investment",
      isShowLogo: true,
      value: formatNumber(count.invest || 0, 2),
      icon: levelInvest?.icon,
    },
    {
      id: "2",
      name: "Daily profit",
      value: formatNumber(count.daily_profit || 0, 2),
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "3",
      name: "Total payback",
      value: formatNumber(count.total_payback || 0, 2),
      icon: "assets/images/total_payback.svg",
    },
  ];

  return (
    <Layout>
      <div>
        <div className="pb-4">
          <MemberCategory data={memberCategories} />
        </div>
        <div className="pb-10">
          <InvestCategory data={investCategories} />
        </div>
      </div>
      <div className="home-page-wrapper">
        <Hero className="mb-10" />
        {/* <CreateNft /> */}
        {/* <TrendingSection trending={trending} className="mb-10" /> */}
        {/* <SellHistoryMarketVisitorAnalytic className="mb-10" /> */}
        {/* <TopSellerTopBuyerSliderSection className="mb-10" /> */}
        {/* <UpdateTable className="mb-10" /> */}
      </div>
    </Layout>
  );
}
