import { IconPackage } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext } from "react";

export default function Package({ name, percent, icon, value }) {
  const { system } = useContext(GlobalContext);

  return (
    <div className="relative mt-10">
      <div className="absolute p-3 rounded-full bg-[#046ce6] left-1/2 -translate-x-1/2 -top-8 w-[80px] h-[80px]">
        {<IconPackage url={icon} />}
      </div>
      <div
        className="p-2 font-bold text-center dark:text-white rounded-lg"
        style={{
          background:
            "linear-gradient(266.52deg, #ecc101 6.69%, #0015b5 92.08%)",
        }}
      >
        <p className="mt-10"></p>
        <p>
          {value} <span className="text-[#ff9800]">{system.token}</span>
        </p>
        <div
          className="dark:text-white rounded-lg p-2"
          style={{
            background:
              "linear-gradient(to right, rgba(59,82,255,.3) 0%,rgba(207,5,213,.3) 99%)",
          }}
        >
          <p>Direct commission</p>
          <p className="text-[#4caf50]">{percent}%</p>
        </div>
      </div>
    </div>
  );
}
