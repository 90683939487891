export default function InvestItem({
  id,
  thumbnail,
  name,
  percent,
  onSelect,
  checked,
}) {
  return (
    <div
      onClick={() => onSelect(id)}
      className="border-[#046ce6] border p-2 rounded-lg cursor-pointer"
    >
      <div className="relative">
        <input
          checked={checked}
          type="radio"
          name=""
          id={id}
          className="absolute top-0 left-0"
        />
        <div className="dark:text-white text-center">
          <img src={thumbnail} alt="invest item" className="m-auto h-[50px]" />
          <p>
            {name} ({percent}%)
          </p>
        </div>
      </div>
    </div>
  );
}
