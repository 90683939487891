import { IconPackage } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext } from "react";

export default function Category({ name, value, icon, isShowLogo }) {
  const { system } = useContext(GlobalContext);

  return (
    <div className="flex items-center gap-3 border border-[#046ce6] p-3 rounded-lg">
      <div className="p-3 rounded-full bg-[#046ce6]">
        {isShowLogo ? (
          <IconPackage
            url={icon}
            width={40}
            height={40}
            heightLogo={20}
            widthLogo={20}
            left={"10px"}
            top={"12px"}
          />
        ) : (
          <img src={icon} alt="icon" className="h-10 w-10" />
        )}
      </div>
      <div className="dark:text-white">
        <p>{name}</p>
        <div
          className="flex text-white justify-center items-center file:font-bold py-1 px-3 rounded-lg"
          style={{
            background:
              "linear-gradient(266.52deg, #0015b5 6.69%, #ecc101 92.08%)",
          }}
        >
          {value}
          <span className="text-[#ff9800] ml-[3px]">{system.token}</span>
        </div>
      </div>
    </div>
  );
}
