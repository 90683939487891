import { useRef } from "react";
import Layout from "../Partials/Layout";
import Deposit from "./Deposit";
import RecentTransactionWidget from "./RecentTransactionWidget";
import Withdraw from "./Withdraw";

export default function MyWallet() {
  const refHistory = useRef();

  const reload = () => {
    refHistory.current.reload();
  };

  return (
    <>
      <Layout>
        <div className="my-wallet-wrapper w-full mb-10">
          <div className="main-wrapper w-full">
            <div className="balance-inquery w-full lg:h-[530px] lg:flex lg:space-x-11 mb-11">
              <div className="lg:w-1/2 h-full mb-10 lg:mb-0">
                <Deposit />
              </div>
              <div className="flex-1">
                <div className="h-full">
                  <Withdraw reload={reload} />
                </div>
              </div>
            </div>
            {/* flex space-x-11 */}
            <div className="recent-and-investment  2xl:gap-[40px] xl:gap-7 gap-4 lg:h-[416px] w-full  justify-between ">
              <div className=" h-full">
                <RecentTransactionWidget ref={refHistory} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
