import Category from "./Category";

export default function MemberCategory({ data }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {data.map((d) => (
        <Category
          key={d.id}
          name={d.name}
          value={d.value}
          icon={d.icon}
          unit={d.unit}
        />
      ))}
    </div>
  );
}
