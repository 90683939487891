/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import background from "../../assets/images/shape/balance-bg.svg";
import { GlobalContext } from "contexts/GlobalContext";
import QRCode from "react-qr-code";
import { IconCommon } from "components/Helpers/Icons";
import { copyText, getAddress, getTextByNumber } from "utils/common";
import { toast } from "react-toastify";

export default function Deposit() {
  const { system, auth, wallet, getWallet } = useContext(GlobalContext);

  useEffect(() => {
    getWallet();
  }, []);

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  return (
    <div
      className="current-balance-widget w-full h-full rounded-2xl overflow-hidden flex flex-col   px-8 py-9"
      style={{
        background: `url(${background}) 0% 0% / cover no-repeat`,
      }}
    >
      <div className="heading mb-5 flex justify-between items-center">
        <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
          Deposit
        </h3>
      </div>
      <div className="text-center mb-2">
        <p className="font-bold  text-dark-gray dark:text-white flex justify-center">
          <IconCommon name="usdt.png" className={"mr-1"} /> Accept only USDT (
          BSC / BEP20 )
        </p>
        <p className="text-[12px] text-gray-300 ">
          Sending any other coin or token to this address may result in the loss
          of your deposit.
        </p>
      </div>
      <div className="flex justify-center">
        {wallet && (
          <QRCode
            title="Wallet"
            value={wallet.address}
            // bgColor={back}
            // fgColor={fore}
            size={200}
          />
        )}
      </div>
      <div className="text-center text-dark-gray dark:text-white font-bold mt-5">
        <div className="flex items-center justify-center">
          <p className="url-share mr-1">{getAddress(wallet?.address)}</p>
          <IconCommon
            name="copy.svg"
            width={20}
            height={20}
            onClick={(_) => handleCopy(wallet?.address)}
          />
        </div>
      </div>
    </div>
  );
}
