/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import profile from "assets/images/profile-info-profile.png";
import Button from "components/Helpers/Button";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import UploadService from "services/uploads";
import UserService from "services/users";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs/InputCom";

export default function PersonalInfoTab() {
  const { auth, getUserAuth } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [profileImg, setProfileImg] = useState(profile);

  // profile img
  const profileImgInput = useRef(null);

  const [state, setState] = useState(null);

  useEffect(() => {
    if (auth) {
      setState(auth);
    }
  }, [auth]);

  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { first_name, last_name, description } = state;
      await UserService.editUser({
        body: {
          first_name,
          last_name,
          description,
        },
      });
      setLoading(false);
      getUserAuth();
      toast.success("Success");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setState(auth);
  };

  const browseProfileImg = () => {
    profileImgInput.current.click();
  };

  const profileImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        setProfileImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);

      uploadAvatar(e.target.files[0]);
    }
  };

  const uploadAvatar = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      const res = await UploadService.uploadFile({ body: formData });
      if (res) {
        await UserService.editUser({
          body: {
            avatar: res.filePath,
          },
        });
        getUserAuth();
        toast.success("Success");
      }
    } catch (error) {
      toast.error(error?.errors);
    }
  };

  return (
    state && (
      <div className="personal-info-tab w-full flex flex-col justify-between">
        <div className="flex flex-col-reverse sm:flex-row">
          <div className="flex-1 sm:mr-10">
            <div className="fields w-full">
              <div className="field w-full mb-6">
                <InputCom
                  label="Email"
                  type="text"
                  placeholder="@example"
                  value={auth.email}
                  disabled
                />
              </div>

              {/* first name and last name */}
              <div className="xl:flex xl:space-x-7 mb-6">
                <div className="field w-full mb-6 xl:mb-0">
                  <InputCom
                    disabled={auth?.status_kyc === 'approved'}
                    label="First Name"
                    type="text"
                    name="firstName"
                    placeholder="First Name Here"
                    value={state.first_name}
                    inputHandler={(e) => onChange("first_name", e.target.value)}
                  />
                </div>
                <div className="field w-full">
                  <InputCom
                    disabled={auth?.status_kyc === 'approved'}
                    label="Last Name"
                    type="text"
                    name="last_name"
                    placeholder="Last Name Here"
                    value={state.last_name}
                    inputHandler={(e) => onChange("last_name", e.target.value)}
                  />
                </div>
              </div>

              <div className="field w-full mb-6">
                <h1 className="field-title">Description </h1>
                <div className="input-field mt-2">
                  <div className="input-wrapper w-full ">
                    <textarea
                      disabled={auth?.status_kyc === 'approved'}
                      value={state.description}
                      onChange={(e) => onChange("description", e.target.value)}
                      rows="7"
                      className="w-full h-full px-7 py-4  border border-light-purple dark:border-[#5356fb29]  rounded-[20px] text-dark-gray dark:text-white bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
              {/* email */}
              {/* <div className="field w-full mb-6">
              <InputCom
                label="Email"
                type="email"
                name="email"
                placeholder="https:yoursite.lo/imte/item_name123"
                value={datas}
                inputHandler={lstNmeHndlr}
              />
              <p className="text-thin-light-gray text-sm tracking-wide mt-3">
                Your email for marketplace notifications
              </p>
            </div> */}
            </div>
            {/* border line */}
            <div className="w-full h-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mb-6"></div>
          </div>
          <div className="w-[232px] mb-10">
            <div className="update-profile w-full mb-9">
              <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white flex items-center mb-2">
                Update Profile
                <span className="ml-1">
                  <Icons name="block-question" />
                </span>
              </h1>
              <p className="text-base text-thin-light-gray mb-5">
                Profile of at least Size
                <span className="ml-1   text-dark-gray dark:text-white">
                  300x300
                </span>
                . Gifs work too.
                <span className="ml-1   text-dark-gray dark:text-white">
                  Max 5mb
                </span>
                .
              </p>
              <div className="flex justify-center">
                <div className="w-full relative">
                  <img
                    src={auth.avatar || profileImg}
                    alt=""
                    className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover"
                  />
                  <input
                    ref={profileImgInput}
                    onChange={(e) => profileImgChangHandler(e)}
                    type="file"
                    className="hidden"
                  />
                  <div
                    onClick={browseProfileImg}
                    className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                        fill="white"
                      />
                      <path
                        d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {auth?.status_kyc !== 'approved' && (
          <div className="content-footer w-full">
            <div className="w-full h-[120px] border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
              <div className="flex space-x-4 mr-9">
                <button
                  type="button"
                  className="text-18 text-light-red tracking-wide "
                  onClick={handleCancel}
                >
                  <span className="border-b dark:border-[#5356fb29]  border-light-red">
                    {" "}
                    Cancel
                  </span>
                </button>
                <Button
                  loading={loading}
                  onClick={(_) => handleSubmit()}
                  className="w-[152px] h-[46px] flex justify-center items-center btn-gradient text-base rounded-full text-white"
                >
                  {" "}
                  Upadate Profile
                </Button>
              </div>
            </div>
          </div>
        )}
        
      </div>
    )
  );
}
