import DarkModeContext from "components/Contexts/DarkModeContext";
import { useContext } from "react";

export default function Category({ name, value, icon, unit }) {
  const { colors } = useContext(DarkModeContext);

  return (
    <div
      className={`flex items-center gap-3 border border-[${colors.blue_600}] p-3 rounded-lg`}
    >
      <div className={`p-3 rounded-full bg-[#046ce6]`}>
        <img src={icon} alt="icon" className="h-10 w-10" />
      </div>
      <div className="dark:text-white">
        <p>{name}</p>
        <div
          className="flex text-white justify-center items-center file:font-bold py-1 px-3 rounded-lg"
          style={{
            background: `linear-gradient(266.52deg, ${colors.blue_600} 6.69%,  ${colors.yellow_600} 92.08%)`,
          }}
        >
          {value}
          <span className="ml-1 text-[#ff9800]">{unit}</span>
        </div>
      </div>
    </div>
  );
}
