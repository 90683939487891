import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatNumber } from "utils/common";
import { INVEST_STYLE_STATUS } from "utils/constant";
import DataIteration from "../Helpers/DataIteration";
import SectionTitle from "../Helpers/SectionTitle";
import ModalInvestDetail from "./ModalInvest/ModalInvestDetail";

export default function History({ data, getInvestHistories }) {
  const { system } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [seletedItem, setSeletedItem] = useState(null);
  
  return (
    <div className="mb-5">
      <SectionTitle name="History" className="mb-5" />
      <div
        className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px]`}
      >
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          <table className="w-full text-base text-dark-gray dark:text-white tracking-wide overflow-hidden">
            <thead className="rounded-md">
              <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29] default-border-b ">
                <th className="p-3">#</th>
                <th className="p-3">Status</th>
                <th className="p-3">Value</th>
                <th className="p-3">Payback</th>
                <th className="p-3">Remain days</th>
                <th className="p-3">Daily profit</th>
                <th className="p-3">Total profit</th>
                <th className="p-3">Created at</th>
              </tr>
            </thead>
            <tbody>
              <DataIteration
                datas={data}
                startLength={0}
                endLength={data.length}
              >
                {({ datas }) => (
                  <tr
                    className="text-center border-b dark:border-[#5356fb29]  hover:bg-gray-50 dark:hover:bg-[#028ffa] cursor-pointer"
                    onClick={() => {
                      setSeletedItem(datas);
                      setOpenModal(true);
                    }}>
                    <td className="p-3">{datas._id}</td>
                    <td className="p-3">
                      <button type="button" className={INVEST_STYLE_STATUS[datas.status][0] + " w-max"}>
                      {INVEST_STYLE_STATUS[datas.status][1]}
                      </button>
                    </td>
                    <td className="p-3">
                      {formatNumber(datas.investment.amount)}{" "}
                      <span className="text-[#ff9800]">{system.token}</span>
                    </td>
                    <td className="p-3">{datas.investment.time_lock} Days</td>
                    <td className="p-3">
                      {" "}
                      {datas.investment.time_lock - datas?.investment?.number_unlock || 0} Days
                    </td>
                    <td className="p-3">
                      <span className="text-[#4caf50]">
                        {formatNumber(datas.investment.daily_profit)}{" "}
                      </span>
                      <span className="text-[#ff9800]">{system.token}</span>
                    </td>
                    <td className="p-3">
                      {formatNumber(datas.investment.total_profit)}{" "}
                      <span className="text-[#ff9800]">{system.token}</span>
                    </td>
                    <td className="p-3">{formatDate(datas.createdAt)} </td>
                  </tr>
                )}
              </DataIteration>
            </tbody>
          </table>
        </div>
      </div>
      <ModalInvestDetail
        setShowModal={setOpenModal}
        showModal={openModal}
        seletedItem={seletedItem}
        callback={getInvestHistories}
      />
    </div>
  );
}
