/* eslint-disable react/jsx-boolean-value */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/auth";
import { toast } from "react-toastify";
import googleLogo from "assets/images/google-logo.svg";
import titleShape from "assets/images/shape/title-shape-two.svg";
import InputCom from "components/Helpers/Inputs/InputCom";
import { ROUTERS } from "config/routes";
import Button from "components/Helpers/Button";
import useQuery from "hooks/useQuery";
import AuthLayout from "../AuthLayout";

export default function SignUp() {
  const navigate = useNavigate();
  const { referred_by } = useQuery();
  const [checked, setValue] = useState(false);
  const [registerValue, setRegisterValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
    referred_by: referred_by,
  });
  const [loading, setLoading] = useState(false);

  const changeValue = (key, value) => {
    setRegisterValue((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const registerHandler = async () => {
    try {
      const { re_password, password, email } = registerValue;
      if (re_password !== password) {
        toast.error("Please enter the correct password");
        return;
      }
      setLoading(true);
      const res = await AuthService.register({
        first_name: registerValue.first_name,
        last_name: registerValue.last_name,
        email: registerValue.email,
        password: registerValue.password,
        referred_by: registerValue.referred_by,
      });

      if (res && res === "REGISTER_SUCCESS") {
        navigate(`${ROUTERS.VERIFY_YOU}?type=signup&email=${email}`);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const rememberMe = () => {
    setValue(!checked);
  };

  return (
    <>
      <AuthLayout slogan="Welcome to  Tosi Bank" isRegister={true}>
        <div className="content-wrapper xl:bg-white dark:bg-dark-white   xl:px-7 px-5 2xl:px-[100px] h-[1040px] rounded-xl flex flex-col justify-center">
          <div>
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
              <h1 className="sm:text-5xl text-4xl font-bold  text-dark-gray dark:text-white leading-2">
                Create Account
              </h1>
              <div className="shape sm:w-[377px] w-[280px] -mt-2.5 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
                <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0">
                  <InputCom
                    placeholder="Adam"
                    label="First Name"
                    name="first_name"
                    type="text"
                    iconName="people"
                    value={registerValue.first_name}
                    inputHandler={(e) =>
                      changeValue("first_name", e.target.value)
                    }
                  />
                </div>
                <div className="input-item flex-1">
                  <InputCom
                    placeholder="Wathon"
                    label="Last Name"
                    name="Last_name"
                    type="text"
                    iconName="people"
                    value={registerValue.last_name}
                    inputHandler={(e) =>
                      changeValue("last_name", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="example@gmail.com"
                  label="Email Address"
                  name="email"
                  type="email"
                  iconName="message"
                  value={registerValue.email}
                  inputHandler={(e) => changeValue("email", e.target.value)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="*********"
                  label="Password"
                  name="password"
                  type="password"
                  iconName="password"
                  value={registerValue.password}
                  inputHandler={(e) => changeValue("password", e.target.value)}
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="*********"
                  label="Re-enter Password"
                  name="password"
                  type="password"
                  iconName="password"
                  value={registerValue.re_password}
                  inputHandler={(e) =>
                    changeValue("re_password", e.target.value)
                  }
                />
              </div>
              <div className="input-item mb-5">
                <InputCom
                  placeholder="TSI_********"
                  label="Referred By"
                  name="referred_by"
                  type="text"
                  iconName="message"
                  disabled={referred_by}
                  value={registerValue.referred_by}
                  inputHandler={(e) => changeValue("referred_by", e.target.value)}
                />
              </div>

              <div className="forgot-password-area flex justify-between items-center mb-6">
                <div className="remember-checkbox flex items-center space-x-2.5">
                  <button
                    onClick={rememberMe}
                    type="button"
                    className="w-5 h-5 text-dark-gray dark:text-white flex justify-center items-center border border-light-gray"
                  >
                    {checked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </button>
                  <span
                    onClick={rememberMe}
                    className="text-base   text-dark-gray dark:text-white"
                  >
                    I agree all
                    <a
                      href="#"
                      className="text-base text-purple mx-1 inline-block"
                    >
                      tarm and condition
                    </a>
                    in TosiBank.
                  </span>
                </div>
              </div>
              <div className="signin-area mb-1">
                <Button
                  className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  loading={loading}
                  onClick={registerHandler}
                >
                  Sign Up
                </Button>
                <button
                  type="button"
                  className="w-full border border-light-purple dark:border-[#5356fb29]  rounded-[50px] h-[58px] flex justify-center bg-[#FAFAFA] dark:bg-[#11131F]  items-center"
                >
                  <img className="mr-3" src={googleLogo} alt="google logo" />
                  <span className="text-lg text-thin-light-gray font-normal">
                    Sign Up with Google
                  </span>
                </button>
              </div>
              <div className="signup-area flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Already have aceount?
                  <a
                    onClick={(_) => navigate(ROUTERS.LOGIN)}
                    className="ml-2   text-dark-gray dark:text-white"
                  >
                    Log In
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
